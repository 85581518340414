<template>
  <slot v-if="!props.mobile ^ isMobile" />
</template>

<script setup>
const props = defineProps({
  server: Boolean,
  mobile: Boolean
})

const { isMobile } = useBreakpoints(props)
</script>

<style scoped lang="scss">

</style>
